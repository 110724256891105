import {t} from '@lingui/macro';
import {FunctionComponent, ReactNode} from 'react';
import styled from 'styled-components';
import {OnboardingState, OnboardingSteps} from '../Onboarding';
import {isTablet} from '../../../utils/responsive';

interface Props {
  children: ReactNode;
  stepState: OnboardingState;
}

export const OnboardingContainer: FunctionComponent<Props> = ({
  children,
  stepState,
}) => {
  return (
    <Col>
      <Top>
        <Steps>
          {stepState.currentStep === OnboardingSteps.FILL_IN_CONTENT ||
          stepState.currentStep === OnboardingSteps.SET_STYLE_URL ? (
            <StepsCount>
              {t`Step 2:`} <StepsTitle>{t`Content`}</StepsTitle>
            </StepsCount>
          ) : (
            <StepsCount>
              {t`Step 1:`} <StepsTitle>{t`Profile`}</StepsTitle>
            </StepsCount>
          )}
          <StepsBar>
            {stepState.currentStep === OnboardingSteps.FILL_IN_CONTENT ||
            stepState.currentStep === OnboardingSteps.SET_STYLE_URL ? (
              <>
                <StepActive />
                <StepActive />
                <StepNotActive />
              </>
            ) : (
              <>
                <StepActive />
                <StepNotActive />
                <StepNotActive />
              </>
            )}
          </StepsBar>
        </Steps>
        {stepState.currentStep === OnboardingSteps.SET_STYLE_URL && (
          <>
            <Header>{t`Sorry but your profile is missing enough posts to define your style`}</Header>
            <Title>{t`Instead please choose the profile of a LinkedIn creator that you like.`}</Title>
          </>
        )}
        {stepState.currentStep === OnboardingSteps.FILL_IN_CONTENT && (
          <Header>{t`Let’s create your first post`}</Header>
        )}
        {stepState.currentStep === OnboardingSteps.CHOOSE_USER_CONTEXT && (
          <>
            <Header>{t`Let's get to know each other`}</Header>
            <Title>{t`In order to personalise your experience we need your LinkedIn profile url.`}</Title>
          </>
        )}
      </Top>
      <Bottom>{children}</Bottom>
    </Col>
  );
};

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Title = styled.h2`
  color: ${({theme}) => theme.colors.neutral.shade9};
  font-size: 1.4em;
  font-weight: 400;
  align-self: center;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 2em;
  width: 60em;
  @media (max-width: ${isTablet}) {
    max-width: 100%;
  }
`;

const Steps = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  margin-bottom: 1em;
`;

const StepsCount = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade10};
`;

const StepsTitle = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-weight: 600;
`;

const StepsBar = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;

const StepActive = styled.div`
  background-color: ${({theme}) => theme.colors.primary1.shade2};
  height: 0.6875em;
  width: 3.6875em;
  border-radius: 2.5em;
  @media (max-width: ${isTablet}) {
    width: 2em;
  }
`;

const StepNotActive = styled.div`
  background-color: ${({theme}) => theme.colors.primary1.shade7};
  height: 0.6875em;
  width: 3.6875em;
  border-radius: 2.5em;
  @media (max-width: ${isTablet}) {
    width: 2em;
  }
`;

const Header = styled.span`
  font-size: 3em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  width: 60em;
  @media (max-width: ${isTablet}) {
    max-width: 100%;
  }
`;
