import {useState, FunctionComponent} from 'react';
import styled from 'styled-components';
import {TextInput} from '../../../components/atoms/TextInput';
import {t} from '@lingui/macro';
import HelpRoundedIcon from '../../../components/atoms/Icons/HelpRoundedIcon';
import {Button} from '../../../components/atoms/Button';
import {ProfileUrlStyleToolTip} from './ProfileUrlStyleToolTip';
import {isValidLinkedInProfileOrCompanyUrl} from '../../../utils/isValidLinkedInProfileUrl';
import {isTablet} from '../../../utils/responsive';
import {getBrowserLocale} from '../../../activateLocale';
import {useProfilePostsCheck} from '../../../hooks/profile/profilePostsCheck/useProfilePostsCheck';

interface Props {
  setStyleUrl: (styleUrl: string) => void;
  isLoading: boolean;
  isRedactPost?: boolean;
}

interface Suggestion {
  name: string;
  imageUrl: string;
  linkedInUrl: string;
}

const frCreators: Suggestion[] = [
  {
    name: 'Benoît Dubos',
    imageUrl: 'https://i.ibb.co/BrwhkbL/1695053204498-1.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/benoitdubos/',
  },
  {
    name: 'Juliette Cadot',
    imageUrl: 'https://i.ibb.co/d5PsQRM/1695654816337.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/juliettecadot/',
  },
  {
    name: 'Caroline Rousset',
    imageUrl: 'https://i.ibb.co/j3rN3n8/1662898929601-1.jpg',
    linkedInUrl:
      'https://www.linkedin.com/in/caroline-rousset-creatrice-de-contenus/',
  },
  {
    name: `Thibault Louis`,
    imageUrl: 'https://i.ibb.co/f09NRrf/1694438720863-1.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/thibault-louis/',
  },
];

const enCreators: Suggestion[] = [
  {
    name: `Justin welsh`,
    imageUrl: 'https://i.ibb.co/FqGN8LV/justin.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/justinwelsh/',
  },
  {
    name: `Mita Mallick`,
    imageUrl: 'https://i.ibb.co/bvKFvK0/mita.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/mita-mallick-2b165822/',
  },
  {
    name: `Alex Hormozi`,
    imageUrl: 'https://i.ibb.co/sQFDPD4/alex.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/alexhormozi/',
  },
  {
    name: `Shaun Robinson`,
    imageUrl: 'https://i.ibb.co/1qYCLFS/shaun.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/msshaunrobinson/',
  },
];

export const ChooseStyleUrl: FunctionComponent<Props> = ({
  setStyleUrl,
  isLoading,
  isRedactPost = false,
}) => {
  const [profileUrl, setProfileUrl] = useState<string>('');
  const [profileUrlError, setProfileUrlError] = useState<string>('');
  const [isToolTipVisible, setIsToolTipVisible] = useState<boolean>(false);

  const locale = getBrowserLocale();
  const contentCreators = locale === 'fr' ? frCreators : enCreators;

  const {checkProfilePosts, isLoading: isChecking} = useProfilePostsCheck();

  const validateProfilePosts = async () => {
    if (!isValidLinkedInProfileOrCompanyUrl(profileUrl)) {
      setProfileUrlError(t`Please enter a valid LinkedIn profile URL`);
      return false;
    }

    const hasEnoughPosts = await checkProfilePosts(profileUrl);
    if (!hasEnoughPosts) {
      setProfileUrlError(
        t`Sorry, but your profile needs at least 15 posts to define your style.`,
      );
    }
    return hasEnoughPosts;
  };

  const handleNextStep = async () => {
    if (await validateProfilePosts()) {
      setStyleUrl(profileUrl);
    }
  };

  return (
    <Container $isRedactPost={isRedactPost}>
      <ContentContainer>
        <InputContainer>
          <StyledTextInput
            label={t`LinkedIn Profile URL`}
            placeholderText={'https://www.linkedin.com/in/yourprofile'}
            value={profileUrl}
            onChange={(event) => {
              setProfileUrl(event.target.value);
              setProfileUrlError('');
            }}
            error={!!profileUrlError}
            helperText={profileUrlError}
          />
          <StyledHelpIconContainer
            onClick={() => setIsToolTipVisible(!isToolTipVisible)}>
            <StyledHelpRoundedIcon />
          </StyledHelpIconContainer>
          {isToolTipVisible && (
            <ProfileUrlStyleToolTip setIsToolTip={setIsToolTipVisible} />
          )}
        </InputContainer>
        <SuggestionsContainer
          $profileUrlError={profileUrlError}
          $profileUrl={profileUrl}>
          <Quest>{`Suggestions`}</Quest>
          <Suggestions>
            {contentCreators.map((suggestion, index) => (
              <Suggestion
                key={index}
                onClick={() => {
                  setProfileUrl(suggestion.linkedInUrl);
                  setProfileUrlError('');
                }}>
                <StyledImg src={suggestion.imageUrl} alt={suggestion.name} />{' '}
                {suggestion.name}
              </Suggestion>
            ))}
          </Suggestions>
        </SuggestionsContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button
          label={
            isLoading
              ? t`Loading ...`
              : isRedactPost
                ? `Let's go`
                : t`Generate my post`
          }
          size="big"
          variant="main"
          onClick={handleNextStep}
          disabled={isLoading || isChecking}
          fullWidth
        />
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div<{$isRedactPost: boolean}>`
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 2em;
  width: ${({$isRedactPost}) => ($isRedactPost ? '70%' : '100%')};
`;

const ContentContainer = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  width: 100%;
  box-sizing: border-box;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  padding: 0 15em;
  @media (max-width: ${isTablet}) {
    padding: 0 5em;
  }
`;

const StyledHelpIconContainer = styled.div`
  position: absolute;
  right: 1em;
  cursor: pointer;
`;

const StyledHelpRoundedIcon = styled(HelpRoundedIcon)`
  color: ${({theme}) => theme.colors.neutral.shade7};
`;

const SuggestionsContainer = styled.div<{
  $profileUrlError: string;
  $profileUrl: string;
}>`
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  @media (min-width: ${isTablet}) {
    margin-top: ${({$profileUrlError, $profileUrl}) =>
      $profileUrlError &&
      !isValidLinkedInProfileOrCompanyUrl($profileUrl) &&
      `0.25em`};
  }
`;

const Quest = styled.span`
  color: ${({theme}) => theme.colors.primary4.shade10};
  font-size: 0.75em;
  letter-spacing: 0.0125em;
`;

const Suggestions = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
`;

const Suggestion = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade4};
  border-radius: 3.125em;
  padding: 0.25em;
  padding-right: 1em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-weight: 600;
  font-size: 0.75em;
  letter-spacing: 0.0125em;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5em;
`;

const StyledImg = styled.img`
  height: 1.75em;
  width: 1.75em;
  border-radius: 50%;
`;
