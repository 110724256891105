import {
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import {NavigationBar, SIDEBAR_WIDTH} from '../organisms/NavigationBar';
import {isSmallScreen} from '../../utils/responsive';
import {MenuIcon} from '../atoms/Icons/MenuIcon';
import {RedactaiIcon} from '../atoms/Icons/RedactaiIcon';
import {useLocation, useNavigate} from 'react-router-dom';
import {NavigationBarMobile} from '../organisms/NavigationBarMobile';
import {PricingPopup} from '../organisms/PricingPopup';
import {UserContext} from '../../common/UserContext/user.context';
import {CircularLoader} from '../atoms/CircularLoader';
import {addDays} from 'date-fns';
import {useSubscription} from '../../hooks/subscription/useSubscription';
import SettingsIcon from '../atoms/Icons/SettingsIcon';
import MyPostsIcon from '../atoms/Icons/MyPostsIcon';
import RecyclePostIcon from '../atoms/Icons/RecyclePostIcon';
import FindIdeaIcon from '../atoms/Icons/FindIdeaIcon';
import CreatePostIcon from '../atoms/Icons/CreatePostIcon';
import {t} from '@lingui/macro';
import {SubscriptionInformationCard} from '../molecules/SubscriptionInformationCard';
import {creditsToPost} from '../../utils/creditsToPost';
import ConfettiExplosion from 'react-confetti-explosion';
import {emailVerificationCheck} from '../../utils/emailVerificationCheck';
import {LoadingSubscriptionInformationCard} from '../molecules/LoadingSubscriptionInformationCard';

export interface Route {
  name: string;
  path: string;
  icon: ReactNode;
  isNew?: boolean;
}

interface Props {
  children?: ReactNode;
}

export const Page: FunctionComponent<Props> = ({children}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const location = useLocation();
  const parseQueryParams = (query: string) => new URLSearchParams(query);
  const queryParams = parseQueryParams(location.search);
  const [showPricingPopup, setShowPricingPopup] = useState<boolean>(false);
  const {subscription} = useSubscription();
  const navigate = useNavigate();
  const {me, loading, refetch} = useContext(UserContext);

  const renewalDate = useMemo(() => {
    if (!subscription) return null;

    if (subscription.isInTrial) {
      const trialEndDate = addDays(new Date(subscription.createdAt), 7);
      return trialEndDate;
    }

    return subscription.creditsRenewalDate;
  }, [subscription]);

  useEffect(() => {
    if (
      location.pathname !== '/onboarding' &&
      me?.hasFinishedOnboarding === false
    ) {
      navigate('/onboarding');
    }
  }, [me?.hasFinishedOnboarding, navigate, location.pathname]);

  useEffect(() => {
    if (!me) {
      refetch();
    }
  }, [me, refetch, loading]);

  useEffect(() => {
    const verifyEmail = async () => {
      await emailVerificationCheck(navigate);
    };

    verifyEmail();
  }, [navigate]);

  if (!me || loading) {
    return <CircularLoader />;
  }

  const routes: Route[] = [
    {name: t`Find an idea`, path: '/findIdea', icon: <FindIdeaIcon />},
    {
      name: t`Create a post`,
      path: '/redactPost',
      icon: <CreatePostIcon />,
      isNew: true,
    },
    ...(new Date(me.createdAt) < new Date('2024-10-21T00:00:00')
      ? [
          {
            name: t`Create a post`,
            path: '/redactPostOld',
            icon: <CreatePostIcon />,
          },
        ]
      : []),
    {
      name: t`Recycle a post`,
      path: '/recyclePost',
      icon: <RecyclePostIcon />,
    },
    {name: t`My posts`, path: '/myposts', icon: <MyPostsIcon />},
    {name: t`Settings`, path: '/settings', icon: <SettingsIcon />},
    // {name: t`Help & support`, path: '/help', icon: <HelpIcon />},
  ];

  return (
    <Container>
      {queryParams.get('isNewSubscription') === 'true' && (
        <StyledConfettiExplosion
          force={1.0}
          particleCount={200}
          width={window.innerWidth}
        />
      )}
      <NavigationBarMobileContainer $isActive={isActive}>
        {isActive && (
          <NavigationBarMobile
            {...{isActive, setIsActive, routes, subscription, renewalDate}}
          />
        )}
      </NavigationBarMobileContainer>
      <NavigationBarContainer>
        <NavigationBar {...{routes, subscription, renewalDate}} />
      </NavigationBarContainer>
      <Header>
        <Between>
          <Row>
            <StyledMenuIcon onClick={() => setIsActive(true)} />
            <StyledRedactaiIcon
              onClick={() => navigate('/redactPost')}
              color1="white"
              width={124}
              height={17}
            />
          </Row>
          {subscription && !loading ? (
            <SubscriptionInformationCard
              isMobile
              name={`${me?.firstName} ${me?.lastName}`}
              numberOfPostsLeft={creditsToPost(
                subscription?.remainingCredits || 0,
              )}
              numberOfPosts={creditsToPost(subscription?.product.credits || 0)}
              renewalDate={renewalDate!}
              canceled={!subscription?.isActive}
            />
          ) : (
            <LoadingSubscriptionInformationCard isMobile />
          )}
        </Between>
      </Header>
      <PageContentContainer>{children}</PageContentContainer>
      {showPricingPopup && (
        <PricingPopup onClose={() => setShowPricingPopup(false)} />
      )}
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  background-color: ${({theme}) => theme.colors.neutral.shade13};
  height: 100vh;
`;
const PageContentContainer = styled.div`
  padding-top: 2em;
  padding-left: 2em;
  padding-right: 2em;
  width: 100%;
  overflow-y: scroll;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: ${isSmallScreen}) {
    padding-top: 8em;
  }
  @media (min-width: ${isSmallScreen}) {
    margin-left: calc(${SIDEBAR_WIDTH}em + 2.5em);
  }
`;
const Header = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade14};
  padding: 3em 2.5em;
  padding-bottom: 2em;
  position: fixed;
  top: -1em;
  right: -1em;
  left: -1em;
  z-index: 10;
  @media (min-width: ${isSmallScreen}) {
    display: none;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75em;
`;
const Between = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const StyledMenuIcon = styled(MenuIcon)`
  cursor: pointer;
`;
const NavigationBarMobileContainer = styled.div<{$isActive: boolean}>`
  background-color: #00000099;
  backdrop-filter: blur(3px);
  position: fixed;
  z-index: 20;
  width: 100vw;
  opacity: ${({$isActive}) => ($isActive ? '1' : '0')};
  transition: opacity 0.2s ease-out;
`;
const NavigationBarContainer = styled.div`
  z-index: 20;
  position: fixed;
`;
const StyledRedactaiIcon = styled(RedactaiIcon)`
  cursor: pointer;
`;
const StyledConfettiExplosion = styled(ConfettiExplosion)`
  position: absolute;
  left: 50%;
`;
