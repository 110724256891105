import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {isTablet} from '../../../utils/responsive';
import {t} from '@lingui/macro';
import GeneratingPostIcon from '../../../components/atoms/Icons/GeneratingPostIcon';
import {RedactedPost} from '../../../hooks/redactPost/useRedactPostMutation';
import {LoadingLayout} from '../../redactpost-v2/components/LoadingLayout';
import {useLoadingProgress} from '../../../hooks/redactPostNew/useLoadingProgress';
import {OnboardingState} from '../Onboarding';
import {ChatWithRedactAI} from './ChatWithRedactAI';
import {Profile} from '../../../hooks/profile/profile.ts';

interface Props {
  posts: RedactedPost[];
  isLoading: boolean;
  stepState: OnboardingState;
  profile: Profile;
}

export const PostGeneration: FunctionComponent<Props> = ({
  posts,
  isLoading,
  stepState,
  profile,
}) => {
  const {loading, progress} = useLoadingProgress(isLoading, posts);
  return (
    <Container>
      <Col>
        <Top>
          <Steps>
            <StepsCount>
              {t`Step 3:`} <StepsTitle>{t`Review & publish`}</StepsTitle>
            </StepsCount>
            <StepsBar>
              <StepActive />
              <StepActive />
              <StepActive />
            </StepsBar>
          </Steps>
          {loading ? (
            <Header>{t`Generating posts...`}</Header>
          ) : (
            <Header></Header>
          )}
        </Top>
        {loading && <GeneratingPostIcon />}
      </Col>
      {loading ? (
        <LoadingLayout progress={progress} isLoading={isLoading} />
      ) : (
        <ChatWithRedactAI
          profile={profile}
          posts={posts}
          stepState={stepState}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  height: 100vh;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
`;
const Top = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1.5em;
`;
const Steps = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
`;
const StepsCount = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade10};
  letter-spacing: 0.03125em;
`;
const StepsTitle = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-weight: 600;
`;
const StepsBar = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;
const StepActive = styled.div`
  background-color: ${({theme}) => theme.colors.primary1.shade2};
  height: 0.6875em;
  width: 3.6875em;
  border-radius: 2.5em;
  @media (max-width: ${isTablet}) {
    width: 2em;
  }
`;
const Header = styled.span`
  font-size: 2.75em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
