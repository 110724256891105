import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import DoneIcon from '../atoms/Icons/DoneIcon';
import {Button} from '../atoms/Button';
import LikeIcon from '../atoms/Icons/LikeIcon';
import DislikeIcon from '../atoms/Icons/DislikeIcon';
import CopyIcon from '../atoms/Icons/CopyIcon';
import {t} from '@lingui/macro';
import {getInitials} from '../../utils/getInitials';
import {useSnackbar} from 'notistack';
import {wordCount} from '../../utils/WordCount';
import {useLikeSavedPost} from '../../hooks/savedPostsAndIdeas/useLikeSavedPost';
import {isTablet} from '../../utils/responsive.ts';
import {PreviewPopup} from './popups/PreviewPopup.tsx';
import HeartIcon from '../atoms/Icons/HeartIcon.tsx';
import {HeartIconFilled} from '../atoms/Icons/HeartIconFilled.tsx';
import EditIcon from '../atoms/Icons/EditIcon.tsx';
import {useTheme} from '../theme/theme.ts';
import {useMarkPostAsFavourite} from '../../hooks/savedPostsAndIdeas/useMarkPostAsFavourite.ts';
import {useTracking} from '../../hooks/useTracking.tsx';
import {useSavedPosts} from '../../hooks/savedPostsAndIdeas/useSavedPosts.ts';
import {SavedPostType} from '../../hooks/savedPost/savedPost.types.ts';

interface Props {
  name: string;
  text: string;
  id: string;
  isLoading?: boolean;
  isOnboarding?: boolean;
  headline?: string;
  profilePictureUrl?: string;
}

export const GeneratedPost: FunctionComponent<Props> = ({
  name,
  text,
  id,
  isLoading = false,
  isOnboarding = false,
  headline,
  profilePictureUrl,
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [showpreviewPopup, setShowPreviewPopup] = useState(false);
  const [postText, setPostText] = useState(text);
  const likeSavedPost = useLikeSavedPost();
  const markPostAsFavourite = useMarkPostAsFavourite();
  const theme = useTheme();
  const {trackEvent} = useTracking();
  const {savedPosts: posts} = useSavedPosts();

  const post = posts.find((post: SavedPostType) => post.id === id);

  const isPostFavourite = post ? post.isFavourite : false;

  const likePost = useCallback(
    (like: boolean) => {
      if (!id) {
        return;
      }
      likeSavedPost.mutate({id, like});
    },
    [likeSavedPost, id],
  );

  useEffect(() => {
    if (likeSavedPost.isSuccess) {
      enqueueSnackbar(t`Thanks for your feedback!`, {
        variant: 'success',
      });
    }
  }, [likeSavedPost.isSuccess, enqueueSnackbar]);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(postText);
    if (isOnboarding) {
      trackEvent('Onboarding - Click - Copy post');
    }
    enqueueSnackbar(t`Copied to clipboard`, {
      variant: 'success',
    });
  };

  useEffect(() => {
    setPostText(text);
  }, [text]);

  return (
    <PostContainer>
      <Header>
        {profilePictureUrl ? (
          <ProfilePicture src={profilePictureUrl} />
        ) : (
          <AvatarWrapper>
            {isLoading ? (
              <SkeletonLoader
                $width="3.6em"
                $height="3.6em"
                $borderRadius="50%"
              />
            ) : (
              <Avatar>{getInitials(name)}</Avatar>
            )}
          </AvatarWrapper>
        )}
        <UserInfo>
          {isLoading ? (
            <>
              <SkeletonLoader
                $width="7em"
                $height="1em"
                $marginBottom="0.5em"
              />
              <SkeletonLoader
                $width="10em"
                $height="0.8em"
                $marginBottom="0.5em"
              />
              <SkeletonLoader $width="6em" $height="0.8em" />
            </>
          ) : (
            <>
              <UserName>{name}</UserName>
              <UserSubtitle>
                {headline || t`I use RedactAI because I'm smart`}
              </UserSubtitle>
              <TimeAgo>
                {t`1d`}
                <Dot />
              </TimeAgo>
            </>
          )}
        </UserInfo>
      </Header>
      <Content>
        {isLoading ? (
          <>
            <SkeletonLoader $width="97%" $height="1em" $marginBottom="0.5em" />
            <SkeletonLoader $width="90%" $height="1em" $marginBottom="0.5em" />
            <SkeletonLoader $width="95%" $height="1em" $marginBottom="0.5em" />
            <SkeletonLoader $width="85%" $height="1em" />
          </>
        ) : (
          postText
        )}
      </Content>
      <Footer>
        <FooterInfo>
          {isLoading ? (
            <SkeletonLoader $width="5.7em" $height="1em" />
          ) : (
            <p>
              {wordCount(postText)}{' '}
              {wordCount(postText) > 1 ? t`Words` : t`Word`}
            </p>
          )}
          <AutoSaveStatus>
            {isLoading ? (
              <SkeletonLoader $width="8em" $height="1em" />
            ) : (
              <>
                <DoneIcon />
                <p>{t`Automatically saved`}</p>
              </>
            )}
          </AutoSaveStatus>
        </FooterInfo>
        <FooterActions>
          <SocialActions>
            {isLoading ? (
              <>
                <SkeletonLoader $width="1.7em" $height="1.7em" />
                <SkeletonLoader $width="1.7em" $height="1.7em" />
                <SkeletonLoader $width="1.7em" $height="1.7em" />
                <SkeletonLoader $width="1.7em" $height="1.7em" />
                <SkeletonLoader $width="1.7em" $height="1.7em" />
              </>
            ) : (
              <>
                {!likeSavedPost.isSuccess && (
                  <>
                    <LikeIcon onClick={() => likePost(true)} />
                    <DislikeIcon onClick={() => likePost(false)} />
                  </>
                )}
                {isPostFavourite ? (
                  <HeartIconFilled
                    onClick={() => markPostAsFavourite.mutate(id)}
                  />
                ) : (
                  <HeartIcon onClick={() => markPostAsFavourite.mutate(id)} />
                )}
                <CopyIcon onClick={handleCopy} />
                <StyledEditIcon
                  onClick={() => setShowPreviewPopup(true)}
                  color={theme.colors.neutral.shade11}
                  height={20.5}
                  width={20.5}
                />
              </>
            )}
          </SocialActions>
          <div>
            {isLoading ? (
              <SkeletonLoader $width="100%" $height="2.5em" />
            ) : (
              <Button
                label={t`See preview`}
                size="small"
                variant="main"
                onClick={() => setShowPreviewPopup(true)}
                fullWidth
              />
            )}
          </div>
        </FooterActions>
      </Footer>
      {showpreviewPopup && (
        <PreviewPopup
          setShowPreviewPopup={setShowPreviewPopup}
          name={name}
          initialText={postText}
          postId={id}
          onTextChange={setPostText}
          createdAt={t`Now`}
          headline={headline}
          profilePictureUrl={profilePictureUrl}
        />
      )}
    </PostContainer>
  );
};

const PostContainer = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  height: 60vh;
  width: calc(100% / 3.5);
  border-radius: 1.5em;
  border: 1px solid ${({theme}) => theme.colors.neutral.shade5};
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  @media (max-width: ${isTablet}) {
    width: 90%;
  }
`;

const Header = styled.div`
  display: flex;
  gap: 0.9375em;
`;
const TimeAgo = styled.span`
  font-size: 0.8em;
  opacity: 60%;
  display: flex;
  align-items: center;
`;
const Dot = styled.span`
  width: 0.3em;
  height: 0.3em;
  background-color: ${({theme}) => theme.colors.neutral.shade11};
  border-radius: 50%;
  margin-left: 0.5em;
`;
const AvatarWrapper = styled.div`
  height: 3.6em;
  width: 3.6em;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.neutral.shade5};
  margin-top: 0.15em;
`;

const Avatar = styled.p`
  font-size: 1.25em;
  font-weight: 700;
`;

const ProfilePicture = styled.img`
  height: 3.6em;
  width: 3.6em;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.neutral.shade5};
  margin-top: 0.15em;
`;

const UserInfo = styled.div`
  color: #000000;
  font-size: 1em;
`;

const UserName = styled.p`
  font-weight: 600;
  font-size: 1em;
  letter-spacing: 0.0125em;
  margin: 0;
`;

const UserSubtitle = styled.p`
  font-size: 0.8125em;
  letter-spacing: 0.00625em;
  opacity: 60%;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
`;

const Content = styled.div`
  line-height: 1.5em;
  letter-spacing: 0.03125em;
  color: ${({theme}) => theme.colors.neutral.shade12};
  flex: 1;
  overflow-y: scroll;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const Footer = styled.div``;

const FooterInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875em;
  line-height: 1.25em;
  letter-spacing: 0.0125em;
  font-weight: 600;
`;

const AutoSaveStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375em;
`;

const FooterActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const SocialActions = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  & > * {
    cursor: pointer;
  }
`;

const shimmer = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const SkeletonLoader = styled.div<{
  $width: string;
  $height: string;
  $borderRadius?: string;
  $marginBottom?: string;
}>`
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  border-radius: ${(props) => props.$borderRadius || '0.5em'};
  margin-bottom: ${(props) => props.$marginBottom || '0'};
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite;
`;
const StyledEditIcon = styled(EditIcon)`
  @media (max-width: ${isTablet}) {
    display: none;
  }
`;
