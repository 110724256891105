import {FunctionComponent, useState} from 'react';
import styled from 'styled-components';
import {Button} from '../atoms/Button';
import ArrowRight from '../atoms/Icons/ArrowRight';
import DoneIcon from '../atoms/Icons/DoneIcon';
import {t} from '@lingui/macro';
import {useTheme} from '../theme/theme';
import {PricingSlider} from '../atoms/PricingSlider';
import {ProductPeriod} from '../../hooks/subscription/useSubscription';
import {Product} from '../../hooks/pricing/useProducts';
import {Status} from '../atoms/Status.tsx';

interface UpgradePricingCardProps {
  productsToDisplay: Product[];
  chosenSliderProduct: Product;
  redirectToCheckout: (productId: string, productName: string) => void;
  priceToString: (price: number, period?: ProductPeriod) => string;
  chosenPostsPerMonth: number;
  setChosenPostsPerMonth: (value: number) => void;
  chosenPostsPerMonthMarks: {value: number; label: string}[];
  productToPricePerPost: (product: Product) => string;
  loading: boolean;
  isAnnual: boolean;
  getCurrencySymbol: (useName?: boolean) => string;
  isFreeTrial: boolean;
}
interface BenefitsProps {
  creator?: boolean;
  enterprise?: boolean;
}

const Benefits: FunctionComponent<BenefitsProps> = ({
  creator = false,
  enterprise = false,
}) => {
  const theme = useTheme();
  return (
    <Col>
      {creator ? (
        <>
          <Row>
            <DoneIcon color={theme.colors.primary1.shade2} />
            <YellowText>{t`Unlimited post generation`}</YellowText>
          </Row>
          <Row>
            <DoneIcon color={theme.colors.neutral.shade11} />
            <Text>{t`Unlimited idea generator`}</Text>
          </Row>
          <Row>
            <DoneIcon color={theme.colors.primary1.shade2} />
            <YellowText>{t`Access to ChatWithRedactAI`}</YellowText>
          </Row>
          <Row>
            <DoneIcon color={theme.colors.neutral.shade11} />
            <Text>{t`Unlimited recycle post`}</Text>
          </Row>
          <Row>
            <DoneIcon color={theme.colors.neutral.shade11} />
            <Text>{t`24/5 customer support`}</Text>
          </Row>
        </>
      ) : enterprise ? (
        <>
          <Row>
            <Text>{t`Everything in Creator plus :`}</Text>
          </Row>
          <Row>
            <DoneIcon color={theme.colors.neutral.shade11} />
            <Text>{t`Multiple profiles`}</Text>
          </Row>
          <Row>
            <DoneIcon color={theme.colors.neutral.shade11} />
            <Text>{t`24/7 customer support`}</Text>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <DoneIcon color={theme.colors.neutral.shade11} />
            <Text>{t`8 posts per month`}</Text>
          </Row>
          <Row>
            <DoneIcon color={theme.colors.neutral.shade11} />
            <Text>{t`Unlimited idea generator`}</Text>
          </Row>
          <Row>
            <DoneIcon color={theme.colors.neutral.shade11} />
            <Text>{t`Access to Recycle post`}</Text>
          </Row>
          <Row>
            <DoneIcon color={theme.colors.neutral.shade11} />
            <Text>{t`24/5 customer support`}</Text>
          </Row>
        </>
      )}
    </Col>
  );
};

export const UpgradePricingCard: FunctionComponent<UpgradePricingCardProps> = ({
  productsToDisplay,
  chosenSliderProduct,
  redirectToCheckout,
  priceToString,
  chosenPostsPerMonth,
  setChosenPostsPerMonth,
  chosenPostsPerMonthMarks,
  productToPricePerPost,
  loading,
  isAnnual,
  getCurrencySymbol,
  isFreeTrial,
}) => {
  const [isSlider, setIsSlider] = useState<boolean>(false);
  return (
    <Container>
      {productsToDisplay.map((product, index) => (
        <UpgradeCard key={index} $isCreator={product.isCreator}>
          {product.isCreator && (
            <MostPopularContainer>
              <MostPopularText>{t`78% pick this plan`}</MostPopularText>
            </MostPopularContainer>
          )}
          <Div>
            <Header>{product.isCreator ? t`Creator` : t`Essential`}</Header>
            <Text>
              {product.isCreator
                ? t`Produce high quality LinkedIn content in no time.`
                : t`For casual posting on LinkedIn without hassle.`}
            </Text>
          </Div>
          {isFreeTrial ? (
            <TrialPrice>
              {0}
              {getCurrencySymbol()} <span>/ mo</span>
              <span>{t`for 7 days*`}</span>
            </TrialPrice>
          ) : (
            <TrialPrice>
              {priceToString(product.price, product.period)}
              {getCurrencySymbol()} <span>/ mo*</span>
              {isAnnual && (
                <StyledStatus
                  label={'+ ' + t`2 months FREE`}
                  variant={'completed'}
                />
              )}
            </TrialPrice>
          )}
          <Benefits creator={product.isCreator} />
          <Button
            onClick={() => redirectToCheckout(product.id, product.name)}
            label={isFreeTrial ? t`Start 7 days Free Trial` : t`Upgrade`}
            size="medium"
            variant={product.isCreator ? 'main' : 'inverted'}
            fullWidth
            endIcon={<ArrowRight height={18} width={18} />}
            disabled={loading}
          />
          <Divider />
          {isFreeTrial && (
            <>
              <SmallTrialPrice>
                <span>{t`*Then`}</span>
                {priceToString(product.price, product.period)}
                {getCurrencySymbol()} <span>/ mo</span>
                {isAnnual && (
                  <StyledStatus
                    label={'+ ' + t`2 months FREE`}
                    variant={'completed'}
                  />
                )}
              </SmallTrialPrice>
            </>
          )}
          <Div>
            <Text>
              <StrongText>
                {'*' +
                  t`${priceToString(product.price)} ${getCurrencySymbol(true)} ` +
                  ` ` +
                  t`billed` +
                  ` ` +
                  (product.period === ProductPeriod.YEARLY
                    ? t`annually`
                    : t`monthly`)}
              </StrongText>
              <br />
              {t`excluding taxes | Cancel anytime`}
            </Text>
          </Div>
        </UpgradeCard>
      ))}
      {false && (
        <UpgradeCard $isCreator={false}>
          <Div>
            <Header>{t`Enterprise`}</Header>
            <Text>{t`For copywriters or companies writing posts for others.`}</Text>
          </Div>
          <Benefits enterprise />
          <Divider />
          <Div>
            <TrialPrice>
              {t`Starting at`}{' '}
              {priceToString(
                chosenSliderProduct.price,
                chosenSliderProduct.period,
              )}{' '}
              {getCurrencySymbol()} <span>/ mo</span>
            </TrialPrice>
            <Text>
              <StrongText>
                {t`${priceToString(chosenSliderProduct.price)} ${getCurrencySymbol(true)} ` +
                  ` ` +
                  t`billed` +
                  ` ` +
                  (chosenSliderProduct.period === ProductPeriod.YEARLY
                    ? t`annually`
                    : t`monthly`)}
              </StrongText>
              <br />
              {t`excluding taxes | Cancel anytime`}
            </Text>
          </Div>
          {isSlider && (
            <PricingSlider
              chosenPostsPerMonth={chosenPostsPerMonth}
              setChosenPostsPerMonth={setChosenPostsPerMonth}
              chosenPostsPerMonthMarks={chosenPostsPerMonthMarks}
              productToPricePerPost={productToPricePerPost}
              chosenSliderProduct={chosenSliderProduct}
              getCurrencySymbol={getCurrencySymbol}
            />
          )}
          <Button
            onClick={() => {
              {
                !isSlider
                  ? setIsSlider(true)
                  : redirectToCheckout(
                      chosenSliderProduct.id,
                      chosenSliderProduct.name,
                    );
              }
            }}
            label={t`Upgrade`}
            size="medium"
            variant="main"
            fullWidth
            endIcon={<ArrowRight height={18} width={18} />}
            disabled={loading}
          />
        </UpgradeCard>
      )}
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2em;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;

const UpgradeCard = styled.div<{$isCreator: boolean}>`
  width: ${({$isCreator}) => ($isCreator ? '22em' : '20.7em')};
  padding: 1.5em;
  border-radius: 1.5em;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border: 2px solid ${({theme}) => theme.colors.neutral.shade5};
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  position: relative;
  margin-top: ${({$isCreator}) => ($isCreator ? '1em' : '2.5em')};
  box-shadow: ${({$isCreator}) =>
    $isCreator ? '0px 8px 16px rgba(0, 0, 0, 0.1)' : 'none'};
`;

const Header = styled.span`
  font-size: 2em;
  letter-spacing: -0.03125em;
  font-weight: 700;
  margin: 0;
`;

const TrialPrice = styled.div`
  font-size: 1.6em;
  font-weight: 700;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5em;
  width: 100%;
  & span {
    font-size: 0.7em;
    font-weight: 400;
  }
`;
const SmallTrialPrice = styled(TrialPrice)`
  font-size: 1.4em;
`;

const Text = styled.p`
  font-size: 1em;
  line-height: 1.5em;
  letter-spacing: 0.03125em;
  margin: 0;
`;

const StrongText = styled.span`
  font-weight: 700;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75em;
`;
const Divider = styled.div`
  height: 1px;
  background-color: ${({theme}) => theme.colors.neutral.shade18};
  width: 75%;
`;
const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
const YellowText = styled(Text)`
  color: ${({theme}) => theme.colors.primary1.shade2};
  font-weight: 700;
  font-size: 1.1em;
`;

const MostPopularContainer = styled.div`
  position: absolute;
  top: -1em;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

const MostPopularText = styled.p`
  font-size: 1.1em;
  font-style: italic;
  background-color: ${({theme}) => theme.colors.primary1.shade2};
  color: ${({theme}) => theme.colors.neutral.shade1};
  text-align: center;
  width: fit-content;
  padding: 0.2em 0.5em;
  font-weight: 500;
  margin: 0 0 3em 0;
`;

const StyledStatus = styled(Status)`
  font-size: 0.9em;
  padding: 0.3em 0.5em;
  span {
    font-weight: 700;
  }
`;
