import {useMutation, useQueryClient} from '@tanstack/react-query';
import {t} from '@lingui/macro';
import {useCallback, useEffect, useState} from 'react';
import {enqueueSnackbar} from 'notistack';
import {SavedPostLanguage} from '../../savedPost/savedPost.types.ts';
import {Profile} from '../profile.ts';

interface NewProfileDto {
  linkedInContextUrl: string;
  name?: string;
  linkedInStyleUrl?: string;
  language?: SavedPostLanguage;
}

export interface ProfileResponse {
  profile: Profile;
  status: 'OK' | 'NOT_ENOUGH_POSTS';
}

export const useCreateProfile = () => {
  const [createdProfile, setCreatedProfile] = useState<
    ProfileResponse | undefined
  >(undefined);
  const queryClient = useQueryClient();

  const createProfileMutation = useMutation({
    onSuccess: async () => {
      queryClient.removeQueries({queryKey: ['profiles']});
      await queryClient.invalidateQueries({queryKey: ['subscription']});
      await queryClient.invalidateQueries({queryKey: ['profiles']});
    },
    mutationFn: async (profile: NewProfileDto) => {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/profile/create`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(profile),
        },
      );

      if (response.ok) {
        return response;
      }

      throw new Error(t`Please check if all the fields are filled correctly`);
    },
  });

  const createProfile = useCallback(
    async (profile: NewProfileDto) => {
      return createProfileMutation.mutate(profile);
    },
    [createProfileMutation],
  );

  useEffect(() => {
    if (!createProfileMutation.isError) {
      return;
    }
    enqueueSnackbar(
      t`Failed to create your profile. Please check that you entered a valid LinkedIn profile url and try again.`,
      {
        variant: 'error',
      },
    );
  }, [createProfileMutation.error, createProfileMutation.isError]);

  useEffect(() => {
    const fetchCreatedProfile = async () => {
      if (
        createProfileMutation.isError ||
        !createProfileMutation.isSuccess ||
        !createProfileMutation.data
      ) {
        return;
      }
      const response = createProfileMutation.data as Response;
      if (!response.ok) {
        return;
      }
      const data = (await response.json()) as ProfileResponse;
      setCreatedProfile(data);
    };

    void fetchCreatedProfile();
  }, [
    createProfileMutation.data,
    createProfileMutation.isError,
    createProfileMutation.isSuccess,
  ]);

  return {
    createProfile,
    createdProfile,
    setCreatedProfile,
    isLoading: createProfileMutation.isPending,
    isSuccess: createProfileMutation.isSuccess,
  };
};
