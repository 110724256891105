import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {Page} from '../../components/templates/Page';
import styled from 'styled-components';
import {usePostToneOptions} from '../../hooks/usePostToneOptions';
import {
  RedactContentSource,
  RedactPostContent,
} from '../redactpost/components/RedactPostContent';
import {t} from '@lingui/macro';
import {Button} from '../../components/atoms/Button';
import {isTablet} from '../../utils/responsive';
import ArrowRight from '../../components/atoms/Icons/ArrowRightIcon';
import {RedactPostGeneration} from '../redactpost/components/RedactPostGeneration';
import {useLocation, useNavigate} from 'react-router-dom';
import {PricingPopup} from '../../components/organisms/PricingPopup';
import {resetErrors, validateForm} from '../../utils/validateForm';
import {
  ContentFromIdeas,
  ContentFromUrl,
  PostContentSource,
  PostStyleSource,
  PostToRedact,
  StyleFromProfile,
} from '../../hooks/redactPost/redactPost.types';
import {RedactPostProfile} from './components/RedactPostProfile';
import {useSavedProfiles} from '../../hooks/redactPostNew/useSavedProfiles';
import {EditCreateProfile} from './components/EditCreateProfile';
import {CreateProfilePopup} from './components/CreateProfilePopup';
import {useRedactPost} from '../../hooks/redactPostNew/useRedactPost';
import {Profile} from '../../hooks/profile/profile.ts';

export const RedactPostNew: FunctionComponent = () => {
  const postToneOptions = usePostToneOptions();
  const [postIdeas, setPostIdeas] = useState<string>('');
  const [postSubject, setPostSubject] = useState<string>('');
  const [contentUrl, setContentUrl] = useState<string>('');
  const [profileUrl, setProfileUrl] = useState<string>('');
  const [contentSource, setContentSource] = useState<RedactContentSource>(
    RedactContentSource.MY_OWN_IDEAS,
  );
  const [readyToRedact, setReadyToRedact] = useState<boolean>(false);
  const [postSubjectError, setPostSubjectError] = useState<string>('');
  const [postSubjectUrlError, setPostSubjectUrlError] = useState<string>('');
  const [contentUrlError, setContentUrlError] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [editProfile, setEditProfile] = useState<boolean>(false);
  const [createProfile, setCreateProfile] = useState<boolean>(false);
  const [profileIdToEdit, setProfileIdToEdit] = useState<string | undefined>(
    undefined,
  );

  const navigate = useNavigate();
  const location = useLocation();
  const {savedProfiles} = useSavedProfiles();
  const [postProfile, setPostProfile] = useState<Profile | undefined>(
    savedProfiles?.[0],
  );

  useEffect(() => {
    const parseQueryParams = (query: string) => new URLSearchParams(query);
    const queryParams = parseQueryParams(location.search);
    const storedData = localStorage.getItem('redactPostFormParameters');

    if (queryParams.has('subject') || queryParams.has('ideas')) {
      setPostSubject(queryParams.get('subject') || '');
      setPostIdeas(queryParams.get('ideas')?.replace(/::/g, '\n') ?? '');
      return;
    }
    if (storedData) {
      if (queryParams.get('isNewSubscription') !== 'true') {
        return;
      }
      const data = JSON.parse(
        localStorage.getItem('redactPostFormParameters') || '{}',
      ) as PostToRedact;
      setPostSubject(data.subject);
      setPostIdeas(
        data.contentSource === PostContentSource.IDEAS
          ? (data.content as ContentFromIdeas).ideas
          : '',
      );
      setContentSource(
        data.contentSource === PostContentSource.URL
          ? RedactContentSource.FROM_AN_URL
          : RedactContentSource.MY_OWN_IDEAS,
      );
      setContentUrl(
        data.contentSource === PostContentSource.URL
          ? (data.content as ContentFromUrl).url
          : '',
      );
      setProfileUrl(
        data.styleSource === PostStyleSource.PROFILE
          ? (data.style as StyleFromProfile).url
          : '',
      );
    }
  }, [
    location.search,
    postToneOptions,
    setContentUrl,
    setPostIdeas,
    setPostSubject,
  ]);

  const {
    onGeneratePostSubmit,
    redactedPosts: posts,
    isLoading,
    showPricingPopup,
    setShowPricingPopup,
    error,
  } = useRedactPost();

  const onWritePost = useCallback(() => {
    setIsSubmitted(true);
    if (
      validateForm(
        contentUrl,
        postSubject,
        profileUrl,
        setContentUrlError,
        setPostSubjectError,
        setPostSubjectUrlError,
        undefined,
        undefined,
        contentSource,
      )
    ) {
      return;
    }
    resetErrors(
      setContentUrlError,
      setPostSubjectError,
      setPostSubjectUrlError,
      setIsSubmitted,
    );
    onGeneratePostSubmit(
      postSubject,
      postIdeas,
      postProfile!.id,
      '',
      undefined,
      contentUrl,
    );
    setReadyToRedact(true);
  }, [
    contentSource,
    contentUrl,
    onGeneratePostSubmit,
    postIdeas,
    postProfile,
    postSubject,
    profileUrl,
  ]);

  const onReset = () => {
    setPostIdeas('');
    setPostSubject('');
    setContentUrl('');
    setContentSource(RedactContentSource.MY_OWN_IDEAS);
    setReadyToRedact(false);
    resetErrors(
      setContentUrlError,
      setPostSubjectError,
      setPostSubjectUrlError,
      setIsSubmitted,
    );
  };

  useEffect(() => {
    if (showPricingPopup || error) {
      setReadyToRedact(false);
    }
  }, [showPricingPopup, error]);

  return (
    <Page>
      {readyToRedact ? (
        <RedactPostGeneration
          posts={posts}
          contentSource={contentSource}
          onChangeInputs={() => {
            setReadyToRedact(false);
          }}
          onNewPost={onReset}
          isLoading={isLoading}
          profile={postProfile}
        />
      ) : editProfile ? (
        <EditCreateProfile
          isEdit={true}
          profileIdToEdit={profileIdToEdit}
          savedProfiles={savedProfiles}
          setEditProfile={setEditProfile}
        />
      ) : createProfile ? (
        <EditCreateProfile isEdit={false} setCreateProfile={setCreateProfile} />
      ) : (
        <Container>
          <Header>
            <Title>{t`Create a post`}</Title>
            <NoIdea>
              {t`No idea?`}{' '}
              <FindIdeaLink onClick={() => navigate('/findIdea')}>
                {t`Find an idea here →`}
              </FindIdeaLink>
            </NoIdea>
          </Header>
          <DeprecatedWarning>{t`This new generator is in test phase. You have access to multiple profiles for free during the test.`}</DeprecatedWarning>
          <RedactPostContent
            contentSource={contentSource}
            setContentSource={setContentSource}
            postSubject={postSubject}
            setPostSubject={setPostSubject}
            postIdeas={postIdeas}
            setPostIdeas={setPostIdeas}
            contentUrl={contentUrl}
            setContentUrl={setContentUrl}
            contentUrlError={contentUrlError}
            postSubjectError={postSubjectError}
            postSubjectUrlError={postSubjectUrlError}
            isSubmitted={isSubmitted}
          />
          <RedactPostProfile
            setPostProfile={setPostProfile}
            postProfile={postProfile}
            savedProfiles={savedProfiles}
            setEditProfile={setEditProfile}
            setCreateProfile={setCreateProfile}
            setProfileIdToEdit={setProfileIdToEdit}
          />
          <End>
            <Button
              label={t`Reset`}
              size="medium"
              variant="light"
              onClick={onReset}
            />
            <Button
              onClick={onWritePost}
              label={t`Write my post`}
              size="medium"
              variant="main"
              endIcon={<StyledArrowRight width={22} height={23} />}
            />
          </End>
        </Container>
      )}
      {showPricingPopup && (
        <PricingPopup onClose={() => setShowPricingPopup(false)} />
      )}
      <CreateProfilePopup />
    </Page>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  width: min(1100px, 80%);
  @media (max-width: ${isTablet}) {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 1.5em;
  font-weight: 700;
  margin: 0;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;

const NoIdea = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
`;

const FindIdeaLink = styled.span`
  color: ${({theme}) => theme.colors.primary4.shade1};
  cursor: pointer;
  text-decoration: underline;
`;

const End = styled.div`
  width: 100%;
  display: flex;
  gap: 1.25em;
  justify-content: end;
  @media (max-width: ${isTablet}) {
    flex-direction: column-reverse;
    gap: 1.5em;
  }
`;

const StyledArrowRight = styled(ArrowRight)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;

const DeprecatedWarning = styled.div`
  color: ${({theme}) => theme.colors.neutral.shade1};
  font-size: 1.1em;
  font-weight: 600;
  background-color: #70b2ff;
  padding: 1em 2.5em;
  text-align: center;
  border-radius: 1em;
  cursor: pointer;
`;
