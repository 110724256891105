import {Dispatch, FunctionComponent, SetStateAction} from 'react';
import {t} from '@lingui/macro';
import styled from 'styled-components';
import {RedactPostProfileSelect} from './RedactPostProfileSelect';
import {Profile} from '../../../hooks/profile/profile.ts';

interface Props {
  postProfile: Profile | undefined;
  setPostProfile: Dispatch<SetStateAction<Profile | undefined>>;
  savedProfiles: Profile[];
  setEditProfile: Dispatch<SetStateAction<boolean>>;
  setCreateProfile: Dispatch<SetStateAction<boolean>>;
  setProfileIdToEdit: Dispatch<SetStateAction<string | undefined>>;
}

export const RedactPostProfile: FunctionComponent<Props> = ({
  postProfile,
  setPostProfile,
  savedProfiles,
  setEditProfile,
  setCreateProfile,
  setProfileIdToEdit,
}) => {
  return (
    <ContentContainer>
      <P>{t`Profile`}</P>
      <RedactPostProfileSelect
        selectedProfile={postProfile}
        setSelectedProfile={setPostProfile}
        profileOptions={savedProfiles}
        setEditProfile={setEditProfile}
        setCreateProfile={setCreateProfile}
        setProfileIdToEdit={setProfileIdToEdit}
      />
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  margin-top: -0.5em;
  width: 100%;
  box-sizing: border-box;
  border: 3px solid ${({theme}) => theme.colors.neutral.shade4};
`;

const P = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
