import {useMutation} from '@tanstack/react-query';
import {t} from '@lingui/macro';
import {useCallback, useState} from 'react';

export const useProfilePostsCheck = () => {
  const [hasEnoughPosts, setHasEnoughPosts] = useState<boolean | undefined>(
    undefined,
  );

  const postsCheckMutation = useMutation({
    mutationFn: async (linkedInStyleUrl: string): Promise<boolean> => {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/profile/hasEnoughPostsForStyle?linkedInStyleUrl=${encodeURIComponent(linkedInStyleUrl)}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        },
      );
      if (!response.ok) {
        throw new Error(t`Failed to check profile status`);
      }
      const data = await response.json();
      return data.hasEnoughPosts;
    },
  });

  const checkProfilePosts = useCallback(
    async (linkedInStyleUrl: string): Promise<boolean | undefined> => {
      setHasEnoughPosts(undefined);
      const hasEnough = await postsCheckMutation.mutateAsync(linkedInStyleUrl);
      setHasEnoughPosts(hasEnough);
      return hasEnough;
    },
    [postsCheckMutation],
  );

  return {
    checkProfilePosts,
    hasEnoughPosts,
    isLoading: postsCheckMutation.isPending,
    isSuccess: postsCheckMutation.isSuccess,
  };
};
